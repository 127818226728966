<template>
	<div v-if="editor" class="flex flex-row flex-wrap gap-1 p-1 bg-background-300 rounded-t-lg">
		<button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
		>
			h1
		</button>
		<button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
		>
			h2
		</button>
		<!-- <button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
		>
			h3
		</button> -->
		<!-- <button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
		>
			h4
		</button>
		<button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
		>
			h5
		</button>
		<button
			type="button"
			class="rounded-lg font-bold uppercase bg-background-200 px-2"
			@click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
			:class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
		>
			h6
		</button> -->
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
			<i class="fas fa-paragraph"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
			<i class="fas fa-list-ul"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
			<i class="fas fa-list-ol"></i>
		</button>
		<!-- <button
			type="button"
			class="border border-[#A1A1AA] px-3 rounded-lg"
			@click="editor.chain().focus().toggleCodeBlock().run()"
			:class="{ 'is-active': editor.isActive('codeBlock') }"
		>
			<i class="fas fa-file-code"></i>
		</button> -->
		<!-- <button
			type="button"
			class="border border-[#A1A1AA] px-3 rounded-lg"
			@click="editor.chain().focus().toggleBlockquote().run()"
			:class="{ 'is-active': editor.isActive('blockquote') }"
		>
			blockquote
		</button> -->
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().setHorizontalRule().run()"><i class="fa-solid fa-ruler-horizontal"></i></button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().setHardBreak().run()">Hard break</button>
		<button
			type="button"
			class="bg-background-200 px-3 h-9 rounded-lg"
			@click="editor.chain().focus().setTextAlign('left').run()"
			:class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
		>
			<i class="fas fa-align-left"></i>
		</button>
		<button
			type="button"
			class="bg-background-200 px-3 h-9 rounded-lg"
			@click="editor.chain().focus().setTextAlign('center').run()"
			:class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
		>
			<i class="fas fa-align-center"></i>
		</button>
		<button
			type="button"
			class="bg-background-200 px-3 h-9 rounded-lg"
			@click="editor.chain().focus().setTextAlign('right').run()"
			:class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
		>
			<i class="fas fa-align-right"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
			<i class="fas fa-bold"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
			<i class="fas fa-italic"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
			<i class="fas fa-underline"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
			<i class="fas fa-strikethrough"></i>
		</button>
		<div>
			<input
				class="bg-background-200 hover:bg-white px-1.5 py-1.5 h-9 w-9 rounded-lg cursor-pointer"
				type="color"
				@input="editor.chain().focus().setColor($event.target.value).run()"
				:value="editor.getAttributes('textStyle').color"
			/>
		</div>

		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().unsetColor().run()">Unset color</button>

		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
			<i class="fas fa-link"></i>
		</button>

		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg cursor-pointer" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
			<i class="fas fa-unlink"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().unsetTextAlign().run()">Unset text Align</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().unsetAllMarks().run()">Clear marks</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().clearNodes().run()">Clear nodes</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().undo().run()">
			<i class="fas fa-undo"></i>
		</button>
		<button type="button" class="bg-background-200 px-3 h-9 rounded-lg" @click="editor.chain().focus().redo().run()">
			<i class="fas fa-redo"></i>
		</button>
	</div>
	<editor-content :editor="editor" />
</template>

<script>
	import { Editor, EditorContent } from '@tiptap/vue-3';
	import StarterKit from '@tiptap/starter-kit';
	import Paragraph from '@tiptap/extension-paragraph';
	import Heading from '@tiptap/extension-heading';
	import TextAlign from '@tiptap/extension-text-align';
	import TextStyle from '@tiptap/extension-text-style';
	import { Color } from '@tiptap/extension-color';
	import Link from '@tiptap/extension-link';
	import Underline from '@tiptap/extension-underline';

	export default {
		components: {
			EditorContent,
		},

		props: {
			modelValue: {
				type: String,
				default: '',
			},
		},

		watch: {
			modelValue(value) {
				const isSame = this.editor.getHTML() === value;
				if (isSame) {
					return;
				}
				this.editor.commands.setContent(value, false);
			},
		},

		data() {
			return {
				editor: null,
			};
		},

		mounted() {
			this.editor = new Editor({
				editable: true,
				extensions: [
					StarterKit,
					Paragraph,
					Heading,
					TextAlign.configure({
						types: ['heading', 'paragraph'],
					}),
					TextStyle,
					Color,
					Link.configure({
						openOnClick: false,
					}),
					Underline,
				],
				content: this.modelValue,
				onUpdate: ({getJSON, getHTML}) => {
					this.$emit('update:modelValue', this.editor.getHTML());
				},
			});
		},

		methods: {
			setLink() {
				const previousUrl = this.editor.getAttributes('link').href;
				const url = window.prompt('URL', previousUrl);

				// cancelled
				if (url === null) {
					return;
				}

				// empty
				if (url === '') {
					this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

					return;
				}

				// update link
				this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
			},
		},

		beforeUnmount() {
			this.editor.destroy();
		},
	};
</script>

<style lang="scss">
	.is-active {
		background-color: #5493f2;
	}
	.ProseMirror {
		padding: 1rem;
		border: 1px solid #45474f;
		background-color: #282828;
		border-radius: 0 0 0.5rem 0.5rem;
	}

	.ProseMirror:focus-visible {
		outline: none;
	}
</style>