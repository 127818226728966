<template>
    <select
        class="bg-background-200 p-2 rounded-lg select min-w-60"
        :value="modelValue"
        @input="$emit('update:modelValue', handleInputChange($event))"
        ref="input"
    >
        <slot />
    </select>
</template>

<script lang="ts">

export default {
    props: ["modelValue"],

    emits: ["update:modelValue"],
    setup: () => {
        // Define event handler for input changes in TypeScript.
        const handleInputChange = (event) => 
        (event.target).value

        return { handleInputChange }
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>