<script setup>
const props = defineProps({
    message: String,
});

const showError = () => {
    return props.message != null;
}
</script>

<template>
    <div v-show="showError">
        <p class="text-sm text-red-400">
            {{ message }}
        </p>
    </div>
</template>