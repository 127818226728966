<template>
    <Default>
        <Head>
            <title>Galeria</title>
        </Head>
        <div class="container mx-auto mt-10 h-full">

            <h1 class="text-2xl mb-6">Galeria</h1>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div
                    v-for="article in articles?.data"
                    :key="article.id"
                    class="bg-background-200 pb-2 rounded-lg hover:cursor-pointer"
                    @click="openArticle(article)"
                >
                    <img loading="lazy" v-if="article.type == 'yt'" :src="`http://img.youtube.com/vi/${article.path}/hqdefault.jpg`" class="w-full aspect-video object-cover"/>
                    <img loading="lazy" v-if="article.type == 'img'" :src="`${article.path}`" class="w-full aspect-video object-cover"/>
                    <div class="px-3 py-3">
                        <p class="text-xl truncate" v-html="article.title"></p>
                        <p class="text-background-600 my-2">{{ dayjs(article.crated_at).format("DD-MM-YYYY") }}</p>
                    </div>
                </div>
            </div>

            <Pagination :pagination="articles.meta" class="pt-6"/>
        </div>
    </Default>
</template>

<script setup>
    import Default from '@/layouts/Default.vue';
    import Pagination from '@/components/Pagination.vue'
    import { Head, router } from '@inertiajs/vue3';
    import { route } from 'ziggy-js';
    import dayjs from 'dayjs'

    defineProps({
        articles: []
    })

    const openArticle = (article) => {
        router.get(route('articles.show', { article: article }));
    }
</script>