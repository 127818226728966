<template>
    <Default>
        <Head>
            <title>O mnie</title>
            <meta name="description" :content="pageContent.content">
        </Head>

        <div class="container mx-auto">
            <!-- Mobile -->
            <div class="lg:hidden flex flex-col mb-10">
                <img loading="lazy" :src="`${pageContent.file_path}`" alt="Mirosław Skowroński" class="object-contain float-none lg:float-left w-full lg:w-96 mr-6 mb-4">
                <p v-html="pageContent.content"></p>
            </div>
            <!-- PC -->
            <div class="hidden lg:block mb-10">
                <img loading="lazy" :src="`${pageContent.file_path}`" alt="Mirosław Skowroński" class="object-contain float-none lg:float-left w-full lg:w-96 mr-6">
                <p v-html="pageContent.content"></p>
            </div>
        </div>
    </Default>
</template>

<script setup>
    import Default from '@/layouts/Default.vue';
    import { Head } from '@inertiajs/vue3'
    import { ref } from 'vue';

    defineProps({
        pageContent: {}
    });

    const text = ref("Absolwent Wydziału Jazzu i Muzyki Estradowej Akademii Muzycznej im. Karola Szymanowskiego w Katowicach, obecnie wykładowca na Wydziale Pedagogiczno – Artystycznym Uniwersytetu im. Adama Mickiewicza w Poznaniu filia w Kaliszu oraz nauczyciel klasy perkusji w Państwowej Szkole Muzycznej I i II stopnia im. Henryka Melcera w Kaliszu. Zdobywca pierwszego miejsca na Festiwalu Jazzu i Muzyki Estradowej w czeskim Primator. Wielokrotny uczestnik Międzynarodowego Festiwalu Pianistów Jazzowych w Kaliszu, muzyk sesyjny, pomysłodawca i organizator warsztatów jazzowych Calisia Forum Jazz. Brał udział nagraniach telewizyjnych w tym dla TVN, TVP2, TVP Polonia, TVP3. Uzyskał tytuł doktora na Wydziale Dyrygentury, Jazzi i Edukacji Muzycznej Akademii im. Feliksa Nowowiejskiego w Bydgoszczy. Współpracował między innymi z Pawlem Tomaszewskim, Mieczysławem Szcześniakiem, Grzegorzem Nagórskim, Piotrem Rodowiczem, Sylwestrem Ostrowskim, Witoldem Janiakiem ( płyta Drum Rudimental ), Michałem Kaczmarczykiem. Obecnie członek kwartetu jazzowego Afanasjew - Szymanowski.");
</script>

<style scoped>
p h1 {
        font-size: 3rem;
}
</style>