<template>
    <input
        ref="input"
        class="input px-4 py-2 text-sm md:text-lg rounded-full h-12 bg-background-200"
        :value="modelValue"
        @input="$emit('update:modelValue', handleInputChange($event))"
    >
</template>

<script setup>

import { onMounted, ref } from 'vue';

const input = ref();

defineProps({
    modelValue: String,
});

defineEmits(['update:modelValue']);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });

const handleInputChange = (event) => (event.target).value
</script>