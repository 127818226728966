<template>
    <Admin title="Galeria" :goBack="true">
        <!-- {{ articles }} -->
        <RouterLink href="admin.articles.create">Dodaj Nowy</RouterLink>
        <table class="w-full table-fixed spacing bg-background-200 border-4 border-background-200 mt-4">
            <thead>
                <tr class="border-b border-background-300">
                    <th class="">
                        <p>Tytuł</p>
                    </th>
                    <th>
                        <p>Data utworzenia</p>
                    </th>
                    <th>
                        <p>Rodzaj Materiału</p>
                    </th>
                    <th>
                        <p>Akcje</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="article in articles.data" :key="article.id" class="border-b border-background-300 h-10">
                    <td>
                        <p class="truncate pr-3" v-html="article.title"></p>
                    </td>
                    <td class="text-center">
                        <p>{{ dayjs(article.crated_at).format("mm:hh - DD-MM-YYYY") }}</p>
                    </td>
                    <td class="text-center">
                        <i :class="getArticleType(article.type)"></i>
                    </td>
                    <td class="text-center flex flex-row gap-2 justify-center">
                        <Link :href="route('admin.articles.edit', { article: article })">
                            <Button class="bg-blue-500 hover:bg-blue-300 px-3">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </Button>
                        </Link>
                        <Button class="bg-red-500 hover:bg-red-300" @click="deleteArticle(article)">
                            <i class="fa-solid fa-trash"></i>
                        </Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination :pagination="articles.meta" class="pt-6"/>
    </Admin>
</template>

<script setup>
import Admin from '@/layouts/Admin.vue';
import Button from '@/components/Button.vue';
import RouterLink from '@/components/RouterLink.vue';
import { Link, router } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import dayjs from 'dayjs'
import Pagination from '@/components/Pagination.vue'

const props = defineProps({
    articles: {}
})

const getArticleType = (type) => {
    switch(type) {
        case "img":
            return "fa-solid fa-photo-film text-primary-400";
        case "yt":
            return "fa-brands fa-youtube text-red-500";
    }
}

const deleteArticle = (article) => {
    if(confirm('Na pewno chcesz usunąć ten artykuł?')) {
        router.delete(route('admin.articles.delete', { article: article }))
    }
}
</script>