<template >
    <div class="bg-black h-screen w-screen flex">
        <div class="container mx-auto my-auto flex items-center justify-center">
            <div class="bg-background-100 rounded-xl p-10 flex flex-col gap-3">
                <h3 class="text-3xl text-center">Login</h3>
                    <div class="flex flex-col gap-1">
                        <Label>Email</Label>
                        <Input type="email" v-model="form.email"/>
                        <InputError :message="errors['email']"/>
                    </div>

                    <div class="flex flex-col gap-1">
                        <Label>Hasło</Label>
                        <Input type="password" v-model="form.password"/>
                    </div>
                    <Button @click="submit" class="btn-md">Zaloguj</Button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import Label from '@/components/Label.vue';
    import Input from '@/components/Input.vue';
    import InputError from '@/components/InputError.vue';
    import {useForm} from '@inertiajs/vue3';
    import {computed} from 'vue';
    import useVuelidate from '@vuelidate/core';
    import { minLength, required, email } from '@vuelidate/validators';
    import Button from '@/components/Button.vue';
    import {route} from 'ziggy-js';

    defineProps({
        errors: []
    })

    const form = useForm({
        email: "",
        password: ""
    });

    const rules = computed(() => {
    return {
        email: {
            required,
            email,
            minLength: minLength(6)
        },
        password: {
            required,
            minLength: minLength(6)
        },
    }
})

const v$ = useVuelidate(rules, form)

const submit = () => {
    v$.value.$validate();

    if(v$.value.$error) {
        return;
    }

    form.post(route('auth.login'))
}
</script>