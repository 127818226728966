<template>
    <Admin title="Galeria - Edytuj" :goBack="true">

        <div class="flex flex-col lg:gap-4">
            
                <div class="flex flex-col gap-2">
                    <Label>Tytuł</Label>
                    <Input v-model="form.title"/>
                    <InputError :message="v$.title?.$errors[0]?.$message"/>
                </div>

                <div class="flex flex-col gap-2">
                    <Label>Treść</Label>
                    <Wysiwyg v-model="form.description"/>
                    <InputError :message="v$.description?.$errors[0]?.$message"/>
                </div>

                <div class="flex flex-col gap-2 items-start">
                    <Label>Typ</Label>
                    <Select v-model="form.type" @change="form.path = null">
                        <option value="yt">Film Youtube</option>
                        <option value="img">Własne Zdjęcie</option>
                    </Select>
                    <InputError :message="v$.type?.$errors[0]?.$message"/>
                </div>

                <div v-if="form.type == 'img'" class="flex flex-col gap-2 items-start">
                    <Label>{{ pathLabel }}</Label>
                    <Input type="file" @input="form.path = $event.target.files[0]"/>
                    <InputError :message="v$.path?.$errors[0]?.$message"/>
                </div>

                <div v-if="form.type == 'yt'" class="flex flex-col gap-2 items-start">
                    <Label>{{ pathLabel }}</Label>
                    <Input v-model="form.path"/>
                    <InputError :message="v$.path?.$errors[0]?.$message"/>
                </div>
            
        </div>

        <Button class="mt-4" @click="submit">Edytuj</Button>
    </Admin>
</template>

<script setup>
import Admin from '@/layouts/Admin.vue';
// import {reactive} from 'vue';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import InputError from '@/components/InputError.vue';
import Label from '@/components/Label.vue';
import Select from '@/components/Select.vue';
import Wysiwyg from '@/components/Wysiwyg.vue';
import {computed, watch} from 'vue';
import {router, useForm, usePage } from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators'

const props = defineProps({
    article: {}
});

const form = useForm({
    title: props.article.title,
    description: props.article.description,
    type: props.article.type,
    path: props.article.type == 'yt' ? `https://www.youtube.com/watch?v=${props.article.path}` : null,
    _method: 'PUT'
});

const pathLabel = computed(() => {
    switch(form.type) {
        case 'img':
            return "Zdjęcie";
        case 'yt':
            return "Link";
    }
});

const rules = computed(() => {
    return {
        title: {
            required,
            minLength: minLength(6)
        },
        description: {
            required,
            minLength: minLength(6)
        },
        type: {
            required,
        },
        path: {
            
        },
    }
})

const v$ = useVuelidate(rules, form)

const submit = () => {
    v$.value.$validate();

    if(v$.value.$error) {
        return;
    }

    form.post(route('admin.articles.update', { article: props.article }), {
        forceFormData: true,
        onSuccess: () => {
            router().push(route('admin.articles.index'))
        },
        onError: (errors) => {

        }
    })
}

</script>