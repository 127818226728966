<template>
    <div class="bg-background-100 h-screen w-screen overflow-x-hidden flex flex-row">
        <!-- Nav -->
        <div class="bg-background-300 min-w-40 flex flex-col items-start px-4 gap-4 pt-5">
            <RouterLink is="link" href="admin.index" class="">
                <h3 class="text-3xl">Panel</h3>
            </RouterLink>

            <hr class="text-surface-600">

            <RouterLink href="index" class="ml-1">Powrót</RouterLink>

            <Link :href="route('admin.pageContents.edit', { pageContent: '1' })" class="ml-1">
                <Button>
                    Strona główna
                </Button>
            </Link>

            <RouterLink href="admin.articles.index" class="ml-1">Galeria</RouterLink>
        </div>
        <!-- Body -->
        <div class="flex-1 flex flex-col">
            <div class="py-5 px-7">
                <h1 class="text-3xl">
                    <Button v-if="goBack" @click="navigateBack" class="rounded-full btn-md w-12"><i class="fa-solid fa-arrow-left text-xl bg-primary-500 text-background-100 rounded-full py-1 px-1.5"></i></Button>
                    {{ title }}
                </h1>
                <hr class="my-4 text-surface-300">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import RouterLink from '@/components/RouterLink.vue';
import Button from '@/components/Button.vue';
import { Link } from '@inertiajs/vue3';
import {route} from "ziggy-js"

const props = defineProps({
    title: {
        default: 'Admin Panel'
    },
    goBack: {
        default: false,
    }
})

const navigateBack = () => {
    window.history.back();
}
</script>