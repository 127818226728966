<template>
    <Admin title="Strona główna - Edytuj" :goBack="true">

        <div class="flex flex-col lg:gap-4">
            <div class="flex flex-col gap-2">
                <Label>Treść</Label>
                <Wysiwyg v-model="form.content"/>
                <InputError :message="v$.content?.$errors[0]?.$message"/>
            </div>


            <div  class="flex flex-col gap-2 items-start">
                <Label>Zdjęcie</Label>
                <Input type="file" @input="form.file_path = $event.target.files[0]"/>
                <InputError :message="v$.file_path?.$errors[0]?.$message"/>
            </div>
        </div>

        <Button class="mt-4" @click="submit">Edytuj</Button>
    </Admin>
</template>

<script setup>
import Admin from '@/layouts/Admin.vue';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import InputError from '@/components/InputError.vue';
import Label from '@/components/Label.vue';
import Wysiwyg from '@/components/Wysiwyg.vue';
import {computed} from 'vue';
import { useForm } from '@inertiajs/vue3';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators'

const props = defineProps({
    pageContent: {}
});

const form = useForm({
    content: props.pageContent.content,
    file_path: null,
    _method: "PUT"
});

const rules = computed(() => {
    return {
        content: {
            required,
        },
        file_path: {
        },
    }
})

const v$ = useVuelidate(rules, form)

const submit = () => {
    v$.value.$validate();

    if(v$.value.$error) {
        return;
    }

    form.post(route('admin.pageContents.update', { pageContent: props.pageContent }), {
        forceFormData: true
    })
}
</script>