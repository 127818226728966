<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import Button from '@/components/Button.vue';
import { route } from 'ziggy-js';

const props = defineProps({
    href: '',
    title: '',
    is: {
        default: 'button'
    },
});

const isActive = computed(() => {
    return route().current() == props.href
})
</script>

<template>
    <Link :href="route(href)">
        <Button v-if="is == 'button'" :class="{'bg-primary-400': isActive}"><slot/>{{ title }}</Button>
        <p v-if="is == 'link'" class="hover:text-primary-500" :class="{'text-primary-400': isActive}"><slot/></p>
    </Link>
</template>

